@tailwind base;
@tailwind components;
@tailwind utilities;

.TextActive {
  @apply text-blue-600;
}

.ant-menu-item {
  @apply !my-0;
}

.ant-menu {
  @apply drop-shadow-sm border border-gray-200 rounded-md;
}
